<template>
  <div class="choose">
    <div
      v-for="item in list"
      :key="item.label"
      @click="clickRadio(item.label)"
      :class="value === item.label ? 'active' : ''"
    >
      <img :src="value === item.label ? item.active : item.src">{{ item.title }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      list: [
        { label: '/policy/tongzhi', title: '申报通知', src: require('@/assets/pc/images/policy/tongzhi_noclick.png'), active: require('@/assets/pc/images/policy/tongzhi_click.png') },
        { label: '/policy/fagui', title: '政策文件', src: require('@/assets/pc/images/policy/fagui_noclick.png'), active: require('@/assets/pc/images/policy/fagui_click.png') }
      ]
    };
  },
  mounted () {},
  computed: {
    ...mapState({
      tongzhiAutoClick: (state) => state.policy.tongzhiAutoClick,
      faguiAutoClick: (state) => state.policy.faguiAutoClick
    })
  },
  methods: {
    clickRadio (label) {
      this.$emit('input', label);
      this.$emit('change', label);
    }
  },
  watch: {
    tongzhiAutoClick () {
      this.$emit('input', '/policy/tongzhi');
      this.$emit('change', '/policy/tongzhi');
    },
    faguiAutoClick () {
      this.$emit('input', '/policy/fagui');
      this.$emit('change', '/policy/fagui');
    }
  }
};
</script>

<style lang="less" scoped>
.choose {
  display: flex;
  padding-top: 20px;
  padding-bottom: 15px;
  div {
    margin-right: 10px;
    border-radius: 5px;
    background-color: #EEEEEE;
    text-align: center;
    line-height: 63px;
    width: 160px;
    height: 63px;
    box-sizing: border-box;
    transition: all .5s;
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: #156ED0;
    }
    img {
      width: 22px;
      margin-right: 10px;
    }
  }

}

</style>
